import {
  MarkdownContent,
  MarkdownContentQuestion,
} from "components/MarkdownContent";

import thumbup from "assets/images/thumbsup.svg";
import thumbsdown from "assets/images/thumbsdown.svg";
import SVGIcon from "components/SVGIcon";
import { BsLayoutSidebarInset, BsLayoutSidebar } from "react-icons/bs";
import { eobRAGPredict } from "api/appServices";
import { sendLogToServer } from "api/logging";
import { createConversationNewChat } from "api/conversation";
import { addMessage } from "api/message";
import { useState } from "react";
import chatsIcon from "assets/images/chats2.svg";
import { PiTrashLight } from "react-icons/pi";
import { deleteConversation } from "api/conversation";
import { updateMessageReactionLiked } from "api/message";
import { Player } from "@lottiefiles/react-lottie-player";

export function captureReactions(reaction, message_id, setChats, feedback) {
  setChats((chats) =>
    chats.map((chat) =>
      chat.message_id === message_id ? { ...chat, liked: reaction } : chat
    )
  );
  updateMessageReactionLiked(reaction, message_id, feedback).then(
    (response) => {
      if (response[0]["reaction_liked"] === true) {
        alert("You liked this message");
      } else if (response[0]["reaction_liked"] === false) {
        alert("You disliked this message");
      }
    }
  );
}

export const filter_fullchat = (
  chat,
  setChats,
  setFilePreview,
  setShowFilePreview,
  userData,
  setShowMessageFeedbackModal,
  orgChatLoader,
  showMessageFeedbackModal
) => {
  return (
    <div
      key={chat.id}
      className={`chat-message pb-4 pt-4 w-full items-center flex justify-center ${
        chat.type === 1 && chat.id !== 0
          ? "bg-[#F7F9FB] dark:bg-foreground-dark"
          : " bg-white dark:bg-background-dark"
      } `}
    >
      <div className={`flex  w-[80%] max-w-[1200px]`}>
        <div
          className={`flex cursor-pointer flex-col space-y-2 text-xs w-full mx-2 order-2 `}
        >
          {chat.loading ? (
            // <Lottie animationData={waveloader} />
            <Player
              autoplay
              loop={true}
              src={orgChatLoader}
              keepLastFrame={false}
            ></Player>
          ) : (
            <div className=" flex flex-row justify-between items-start">
              <span
                className={`px-4 py-2 text-sm 2x:text-base rounded-lg inline-block ${
                  chat.type === 1
                    ? " text-gray-600 dark:text-white"
                    : " text-gray-600 dark:text-white"
                } `}
              >
                {chat.type === 0 ? (
                  <MarkdownContentQuestion content={chat.content.answer_data} />
                ) : chat.just_rendered === true ? (
                  <>
                    <MarkdownContent
                      content={chat.content.answer_data}
                      visualization={chat.content.visualization}
                      source={chat.content.source}
                      setFilePreview={setFilePreview}
                      setShowFilePreview={setShowFilePreview}
                    />
                  </>
                ) : (
                  <>
                    <MarkdownContentQuestion
                      content={chat.content.answer_data}
                      visualization={chat.content.visualization}
                      source={chat.content.source}
                      setFilePreview={setFilePreview}
                      setShowFilePreview={setShowFilePreview}
                    />
                  </>
                )}
              </span>
              {chat.type === 1 && chat.id !== 0 ? (
                <div className=" flex items-center gap-4 justify-center">
                  <div
                    onClick={() => {
                      if (chat.liked === true) {
                        captureReactions(null, chat.message_id, setChats, null);
                      } else {
                        captureReactions(true, chat.message_id, setChats, null);
                      }
                    }}
                  >
                    <SVGIcon
                      id={`thumbup_filter_fullchat${chat.id}`}
                      src={thumbup}
                      stroke="black"
                      className="cursor-pointer group"
                      svg_path_class={` ${
                        chat.liked === true
                          ? "fill-green-200 dark:fill-green-800"
                          : ""
                      } dark:stroke-white group-hover:fill-green-200 dark:group-hover:fill-green-800`}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (chat.liked === false) {
                        captureReactions(null, chat.message_id, setChats, null);
                      } else {
                        setShowMessageFeedbackModal({
                          open: true,
                          message_id: chat.message_id,
                        });
                      }
                    }}
                  >
                    <SVGIcon
                      id={`thumbsdown_filter_fullchat${chat.id}`}
                      src={thumbsdown}
                      stroke="black"
                      className="cursor-pointer group"
                      svg_path_class={` ${
                        chat.liked === false
                          ? "fill-red-200 dark:fill-red-800"
                          : ""
                      } dark:stroke-white group-hover:fill-red-200 dark:group-hover:fill-red-800`}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {chat.loading ? (
          <></>
        ) : chat.end ? (
          <img
            src={
              chat.type === 1 ? userData.org.org_logo : userData.data.avatar_url
            }
            alt="My profile"
            className={` h-8 rounded-full  ${
              chat.type === 1 ? "order-1" : "order-1"
            } `}
          />
        ) : (
          <div
            className={`w-8 h-8 rounded-full   ${
              chat.type === 1 ? "order-1" : "order-1"
            } `}
          />
        )}
      </div>
    </div>
  );
};

export function chatHistoryIconToggle(chatHistoryToggle, setChatHistoryToggle) {
  if (chatHistoryToggle) {
    return (
      <BsLayoutSidebar
        className="cursor-pointer dark:text-white"
        size={26}
        onClick={() => {
          setChatHistoryToggle(!chatHistoryToggle);
        }}
      />
    );
  } else {
    return (
      <BsLayoutSidebarInset
        className="cursor-pointer  dark:text-white"
        size={26}
        onClick={() => {
          setChatHistoryToggle(!chatHistoryToggle);
        }}
      />
    );
  }
}

function getHightestID(chats) {
  const highestId = chats.reduce((maxId, item) => {
    return item.id > maxId ? item.id : maxId;
  }, -1);
  return highestId;
}

const updateChatById = (id, loading, content, setChats) => {
  setChats((prevChats) =>
    prevChats.map((chat) =>
      chat.id === id ? { ...chat, loading, content } : chat
    )
  );
};

const updateChatLIKEDandMESSAGEIDById = (id, liked, message_id, setChats) => {
  setChats((prevChats) =>
    prevChats.map((chat) =>
      chat.id === id ? { ...chat, liked, message_id } : chat
    )
  );
};

function getAnswer(
  content,
  newID,
  setChats,
  user_id,
  conversationId,
  navigate,
  selectedModel,
  chats
) {
  const newIDAnswer = newID + 1;
  setChats((prevChats) => [
    ...prevChats,
    {
      id: newIDAnswer,
      type: 1,
      content: newIDAnswer,
      end: true,
      loading: true,
      just_rendered: true,
    },
  ]);
  const questions_data_list = chats.filter((item) => item.type === 1);
  const latest_question_obj = questions_data_list.reduce((prev, current) => {
    return prev.id > current.id ? prev : current;
  }, questions_data_list[0]); // Start comparing from the first item of the filtered list

  const data = {
    query: content,
    source_documents: true,
    selected_model: selectedModel.model_server_name,
    previous_question: latest_question_obj
      ? latest_question_obj.content.final_query
        ? latest_question_obj.content.final_query
        : ""
      : "",
    user_id: user_id,
  };

  eobRAGPredict(data)
    .then((response) => {
      updateChatById(
        newIDAnswer,
        false,
        response.eob_rag_data.data.Answer,
        setChats,
        true
      );
      if (conversationId) {
        addMessage(conversationId, content, "Question", null, null, null).then(
          () => {
            addMessage(
              conversationId,
              response.eob_rag_data.data.Answer.answer_data,
              "Answer",
              selectedModel.id,
              response.eob_rag_data.data.Answer.visualization,
              response.eob_rag_data.data.Answer.source,
              response.eob_rag_data.data.Answer.final_query
            ).then((message_data) => {
              updateChatLIKEDandMESSAGEIDById(
                newIDAnswer,
                null,
                message_data[0].id,
                setChats
              );
            });
          }
        );
        // add two rows one with content and other with response.eob_rag_data.data.Answer into messages
      } else {
        createConversationNewChat(content, user_id).then((newChatData) => {
          addMessage(
            newChatData[0].id,
            content,
            "Question",
            null,
            null,
            null,
            null
          ).then(() => {
            addMessage(
              newChatData[0].id,
              response.eob_rag_data.data.Answer.answer_data,
              "Answer",
              selectedModel.id,
              response.eob_rag_data.data.Answer.visualization,
              response.eob_rag_data.data.Answer.source,
              response.eob_rag_data.data.Answer.final_query
            ).then(() => {
              navigate(`/chatpage/${newChatData[0].id}`);
            });
          });
        });
      }
    })
    .catch((error) => {
      sendLogToServer(error);
    });
}

export function addQuestion(
  content,
  chats,
  setChats,
  user_id,
  conversationId,
  navigate,
  selectedModel
) {
  const newID = getHightestID(chats) + 1;

  setChats((prevChats) => [
    ...prevChats,
    {
      id: newID,
      type: 0,
      content: {
        answer_heading: content,
        answer_data: content,
      },
      end: true,
      loading: false,
      just_rendered: false,
    },
  ]);

  getAnswer(
    content,
    newID,
    setChats,
    user_id,
    conversationId,
    navigate,
    selectedModel,
    chats
  );
}

export function ChatSkeletonLoader() {
  return (
    <div className="flex w-full  h-full items-center  justify-center">
      <div role="status" className="w-[80%] max-w-[1200px]   animate-pulse">
        <div className="h-2.5 bg-gray-200 rounded-sm dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full"></div>
        <span className="sr-only">Loading...</span>

        <div className="h-2.5 bg-gray-200 rounded-sm dark:bg-gray-700 w-48 mb-4 mt-14"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-sm dark:bg-gray-700 max-w-full"></div>
      </div>
    </div>
  );
}

export function SavedConversationsItem({
  jk,
  k,
  d_convo,
  navigate,
  setIsLoadingChat,
  setConversations,
  conversationId,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div
      onMouseLeave={() => setShowConfirm(false)}
      key={k}
      className="group flex flex-row items-center gap-3 mb-2 ml-3 relative"
    >
      <div>
        <SVGIcon
          id={`chats_today09${k}${jk}`}
          src={chatsIcon}
          fill="black"
          className="cursor-pointer"
          style={{ width: "24px", height: "24px" }} // specify a fixed width and height
          svg_path_class="dark:fill-white"
        />
      </div>
      <h1
        onClick={() => {
          if (conversationId !== d_convo.id) {
            setIsLoadingChat(true);
            navigate(`/chatpage/${d_convo.id}`);
          }
        }}
        className="font-normal text-sm 2xl:text-base dark:text-white cursor-pointer whitespace-nowrap overflow-hidden text-overflow-ellipsis text-ellipsis "
      >
        {d_convo.conversation_name}
      </h1>
      <div className="cursor-pointer absolute backdrop-blur-xl bg-[#FCFCFC] dark:bg-background-dark  pr-2 pl-3 right-0 top-0 bottom-0 flex items-center opacity-0 group-hover:opacity-100">
        {!showConfirm ? (
          <PiTrashLight
            className="delete-icon cursor-pointer fill-current text-black dark:fill-white "
            onClick={() => setShowConfirm(true)}
            size={20}
          />
        ) : (
          <div className="delete-confirm-buttons flex items-center">
            <button
              className="text-green-500 hover:text-green-700"
              onClick={() => {
                deleteConversation(d_convo.id).then((updated_response) => {
                  setConversations((prevConversations) =>
                    prevConversations.filter(
                      (convo) => convo.id !== updated_response[0].id
                    )
                  );
                  if (conversationId === d_convo.id) {
                    navigate("/chatpage");
                  }
                });
                setShowConfirm(false);
              }}
            >
              ✓
            </button>
            <button
              className="text-red-500 hover:text-red-700 ml-2"
              onClick={() => setShowConfirm(false)}
            >
              ✕
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: ChatPageHelper.js
//  Author: Everlign Development Team
// ----------------------------
