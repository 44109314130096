import React from "react";
import { useState, useRef, useEffect } from "react";
import send from "assets/images/send.svg";
import "./style.css";
import Navbar from "components/Navbar";
import SVGIcon from "components/SVGIcon";

import { sendLogToServer } from "api/logging";
import SkeletonLoader from "components/SkeletonLoader";
import {
  filter_fullchat,
  chatHistoryIconToggle,
  addQuestion,
} from "helper/ChatPageHelper";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllConversation } from "api/conversation";
import { getMessages } from "api/message";
import { CiGrid41 } from "react-icons/ci";
import { SlArrowUp } from "react-icons/sl";
import { getModels } from "api/models";
import { ChatSkeletonLoader } from "helper/ChatPageHelper";
import { SavedConversationsItem } from "helper/ChatPageHelper";
import { groupConversationsByDate } from "helper/ChatPageHelper2";
import ModalAllPages from "components/pdf/ModalAllPages";
import { ScrollContainer } from "components/ScrollContainer";
import { captureReactions } from "helper/ChatPageHelper";
import { AiOutlineStop } from "react-icons/ai";
import { deleteAllConversations } from "api/conversation";

export default function ChatPage({ orgChatLoader }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { conversationId } = useParams();

  const textareaRef = useRef(null);

  const [conversations, setConversations] = useState([]);
  const [models, setModels] = useState([]);
  const [toggleModelDropdown, setToggleModelDropdown] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(true);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [chatHistoryToggle, setChatHistoryToggle] = useState(true);
  const userData = useSelector((state) => state.userData);
  const [filePreview, setFilePreview] = useState(null);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [showMessageFeedbackModal, setShowMessageFeedbackModal] = useState({
    open: false,
    message_id: null,
  });

  const [feedback, setFeedback] = useState(null);
  const [showConfirmRemoveAllChats, setShowConfirmRemoveAllChats] =
    useState(false);

  useEffect(() => {
    if (conversationId) {
      getMessages(conversationId).then((messages) => {
        if (messages) {
          let messages_transformed = messages.map((item, index) => {
            return {
              id: index,
              type: item.sender === "Question" ? 0 : 1, // assuming 'Question' is 0 and 'Answer' is 1
              content: {
                answer_heading: item.content, // assuming the content of the question becomes the answer_heading
                answer_data: item.content, // assuming the content of the question becomes the answer_data
                visualization: item.visualization,
                source: item.source_data,
                final_query: item.final_query,
              },
              end: true,
              loading: false,
              just_rendered: false,
              liked: item.reaction_liked,
              message_id: item.id,
            };
          });
          getModels().then((models_response) => {
            setModels(models_response);
            setChats(messages_transformed);
            const mistral_model = models_response.filter(
              (model) => model.model_name === "Mistral"
            );
            setSelectedModel(mistral_model[0]);
            setIsLoadingChat(false);
          });
        } else {
          navigate("/chatpage");
        }
      });
    } else {
      getModels().then((models_response) => {
        setModels(models_response);
        setChats([
          {
            id: 0,
            type: 1, // 0 is question and 1 is answer
            content: {
              answer_heading: `Hi ${userData.data.full_name}, how can I help you?`,
              answer_data: `Hi ${userData.data.full_name}, how can I help you?`,
              visualization: null,
              source: null,
            },
            end: true,
            loading: false,
            just_rendered: true,
            liked: null,
            message_id: null,
          },
        ]);
        const mistral_model = models_response.filter(
          (model) => model.model_name === "Mistral"
        );
        setSelectedModel(mistral_model[0]);
        if (location.state && location.state.question) {
          addQuestion(
            location.state.question,
            chats,
            setChats,
            userData.data.id,
            conversationId,
            navigate,
            mistral_model[0]
          );
        }
        setIsLoadingChat(false);
      });
    }

    getAllConversation()
      .then((conversation) => {
        setConversations(conversation);
        setIsLoadingChatHistory(false);
      })
      .catch((error) => {
        sendLogToServer(error);
      });

    textareaRef.current.focus();
  }, [conversationId, navigate]);

  const [chats, setChats] = useState([
    {
      id: 0,
      type: 1, // 0 is question and 1 is answer
      content: {
        answer_heading: `Hi ${userData.data.full_name}, how can I help you?`,
        answer_data: `Hi ${userData.data.full_name}, how can I help you?`,
        visualization: null,
        source: null,
        final_query: null,
      },
      end: true,
      loading: false,
      just_rendered: true,
      liked: null,
      message_id: null,
    },
  ]);
  const [question, setQuestion] = useState("");

  return (
    <div className=" w-[100%] h-[100vh] flex flex-row dark:bg-background-dark">
      <div
        className={`${
          chatHistoryToggle ? " w-64 2xl:w-80 px-5 py-8 border-r-2 " : "w-0"
        } h-full bg-[#FCFCFC] dark:bg-background-dark dark:border-divider-dark  duration-300`}
      >
        {chatHistoryToggle ? (
          <>
            <div className=" overflow-hidden h-[95%] ">
              <div className="flex flex-row items-center justify-between">
                <h1 className="font-semibold text-xl dark:text-white">Chats</h1>
              </div>

              {isLoadingChatHistory ? (
                <div className="mt-10">
                  <SkeletonLoader />
                </div>
              ) : (
                <div
                  className="h-full overflow-y-auto"
                  style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                >
                  {groupConversationsByDate(conversations).map(
                    (d_convo_date, jk) => (
                      <div key={jk}>
                        {d_convo_date.data.length === 0 ? (
                          <></>
                        ) : (
                          <h1 className="font-semibold dark:text-white text-sm 2xl:text-base mb-2 mt-10 ml-3">
                            {d_convo_date.name}
                          </h1>
                        )}
                        {d_convo_date.data.map((d_convo, k) => (
                          <SavedConversationsItem
                            jk={jk}
                            key={k}
                            k={k}
                            d_convo={d_convo}
                            navigate={navigate}
                            setIsLoadingChat={setIsLoadingChat}
                            setConversations={setConversations}
                            conversationId={conversationId}
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            <div className="h-[5%] flex justify-start items-end">
              {conversations.length !== 0 ? (
                <div
                  onMouseLeave={() => setShowConfirmRemoveAllChats(false)}
                  className="cursor-pointer backdrop-blur-xl pr-2 pl-3 flex items-center"
                >
                  {!showConfirmRemoveAllChats ? (
                    <div
                      onClick={() => setShowConfirmRemoveAllChats(true)}
                      className="flex flex-row"
                    >
                      <AiOutlineStop
                        className="delete-icon cursor-pointer fill-current text-black dark:fill-white "
                        size={20}
                      />
                      <p className="text-sm dark:text-white ml-4">
                        Clear all chats
                      </p>
                    </div>
                  ) : (
                    <div className="delete-confirm-buttons flex items-center">
                      <button
                        className="text-green-500 hover:text-green-700"
                        onClick={() => {
                          deleteAllConversations().then(() => {
                            setConversations([]);
                            setShowConfirmRemoveAllChats(false);
                          });
                        }}
                      >
                        ✓
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700 ml-2"
                        onClick={() => setShowConfirmRemoveAllChats(false)}
                      >
                        ✕
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full h-full ">
        <div className="py-3  h-[10%] 2xl:h-[6%] box-border w-full border-b-2 border-gray-100 dark:border-divider-dark   items-center justify-center flex">
          <Navbar
            component={chatHistoryIconToggle(
              chatHistoryToggle,
              setChatHistoryToggle
            )}
          />
        </div>
        <div className=" justify-center w-full flex h-[75%] 2xl:h-[78%] dark:bg-foreground-dark box-border">
          {filePreview === null ? (
            <></>
          ) : (
            <ModalAllPages
              filePreview={filePreview}
              setShowFilePreview={setShowFilePreview}
              showFilePreview={showFilePreview}
            />
          )}

          <ScrollContainer>
            {isLoadingChat ? (
              <ChatSkeletonLoader />
            ) : (
              chats.map((d, i) =>
                filter_fullchat(
                  d,
                  setChats,
                  setFilePreview,
                  setShowFilePreview,
                  userData,
                  setShowMessageFeedbackModal,
                  orgChatLoader,
                  showMessageFeedbackModal
                )
              )
            )}
          </ScrollContainer>
        </div>
        <div className=" h-[15%] items-center  flex flex-col ">
          <div className="w-[80%] max-w-[1200px]   flex items-start">
            <div className="relative inline-block text-left">
              <div
                className={` ${
                  toggleModelDropdown ? "" : "hidden"
                } absolute mt-2 w-36 bottom-0 mb-12 divide-y divide-gray-100 rounded-md bg-white dark:bg-background-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  {models.map((d, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedModel(d);
                        setToggleModelDropdown(!toggleModelDropdown);
                      }}
                      className="text-gray-700 dark:text-white block px-4 py-2 text-sm cursor-pointer"
                      role="menuitem"
                      id="menu-item-0"
                    >
                      {d.model_name}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {userData.org.colors.is_for_client ? (
                  <div className="h-7 "></div>
                ) : (
                  <button
                    onClick={() => {
                      setToggleModelDropdown(!toggleModelDropdown);
                    }}
                    type="button"
                    className=" inline-flex w-full dark:bg-transparent justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:ring-foreground-dark"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    <CiGrid41 size={11} className="dark:text-white" />
                    <h6 className="text-[10px] dark:text-white">
                      {selectedModel?.model_name}
                    </h6>

                    <SlArrowUp className="ml-4 dark:text-white" size={10} />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="w-[80%] max-w-[1200px] bg-[#F7F9FB] dark:bg-foreground-dark 2xl:h-[56px] h-[42px] rounded-[14px] justify-between gap-3  px-5 flex flex-row ">
            <div className="bg-[#F7F9FB] dark:bg-foreground-dark  flex items-center gap-4 justify-center">
              {/* <SVGIcon
                  id={`MicIcon`}
                  src={mic}
                  fill="black"
                  className="cursor-pointer"
                  svg_path_class="dark:fill-white"
                /> */}
              {/* <SVGIcon
                  id={`ImageUploadIcon`}
                  src={imageIcon}
                  fill="black"
                  className="cursor-pointer"
                  svg_path_class="dark:fill-white"
                /> */}
            </div>
            <textarea
              ref={textareaRef}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  addQuestion(
                    question,
                    chats,
                    setChats,
                    userData.data.id,
                    conversationId,
                    navigate,
                    selectedModel
                  );
                  setQuestion("");
                }
              }}
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              placeholder="Start typing prompt here"
              className="flex-grow text-sm bg-[#F7F9FB] 2xl:h-[56px] h-[42px] dark:bg-foreground-dark px-5 pt-2 2xl:pt-4 outline-none dark:text-white"
              style={{ resize: "none" }} // disable manual resize
            ></textarea>
            <div
              onClick={() => {
                addQuestion(
                  question,
                  chats,
                  setChats,
                  userData.data.id,
                  conversationId,
                  navigate,
                  selectedModel
                );
                setQuestion("");
              }}
              className="bg-[#F7F9FB] dark:bg-foreground-dark flex items-center justify-center"
            >
              <SVGIcon
                id={`SendIcon`}
                src={send}
                fill={question === "" ? "#9FA1A2" : "#000000"}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="crud-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={`${
          showMessageFeedbackModal.open ? "" : "hidden"
        }  fixed inset-0 z-50 flex justify-center items-center w-full backdrop-blur-[2px] h-full overflow-y-auto overflow-x-hidden`}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-foreground-dark">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Feedback
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="crud-modal"
                onClick={() => {
                  setShowMessageFeedbackModal({
                    open: false,
                    message_id: null,
                  });
                  setFeedback(null);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Please share your Feedback ({feedback?.length}/150)
                  </label>
                  <textarea
                    value={feedback ? feedback : ""}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      if (inputText.trim().split(/\s+/).length <= 150) {
                        setFeedback(inputText);
                      }
                    }}
                    id="description"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write feedback here"
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="text-white inline-flex items-center bg-primary-light  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-light"
                onClick={() => {
                  captureReactions(
                    false,
                    showMessageFeedbackModal.message_id,
                    setChats,
                    feedback
                  );
                  setShowMessageFeedbackModal({
                    open: false,
                    message_id: null,
                  });
                  setFeedback(null);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: ChatPage.js
//  Author: Everlign Development Team
// ----------------------------
