import React, { useState } from "react";
import {
  GetStartedSection,
  EnterProviderSection,
} from "helper/GetStartedPageHelper";
import { signInWithEmailPassword } from "api/auth";

import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
export default function GetStartedPage({
  animatedLogo,
  animatedbackground,
  themeData,
}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sectionActivate, setSectionActivate] = useState(1);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        // backgroundColor: "#060715",
        background: themeData.colors.get_started_background,
        overflow: "hidden",
      }}
      className="flex items-center justify-center"
    >
      {animatedbackground && (
        <Player
          autoplay
          loop={false}
          className="absolute inset-0 z-0"
          src={animatedbackground}
          style={{ transform: "scale(1.5)" }}
          keepLastFrame={true}
        ></Player>
      )}
      {animatedLogo && (
        <Player
          autoplay
          loop={false}
          className="absolute inset-0 z-0"
          src={animatedLogo}
          keepLastFrame={true}
        ></Player>
      )}

      {sectionActivate === 1 ? (
        <GetStartedSection
          onClickButton={() => {
            setSectionActivate(2);
          }}
        />
      ) : sectionActivate === 2 ? (
        <EnterProviderSection
          onClickButton={() => {
            signInWithEmailPassword(email, password).then(() => {
              navigate("/homepage");
            });
          }}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      ) : // : sectionActivate === 3 ? (
      //   <EnterOTPSection
      //     changeEmailClick={() => {
      //       setSectionActivate(2);
      //     }}
      //   />
      // )
      null}
    </div>
  );
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: GetStartedPage.js
//  Author: Everlign Development Team
// ----------------------------
